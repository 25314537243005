import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
// import reportWebVitals from "./reportWebVitals";

let el = document.getElementById("airDolomitiWidget");
let lang = el.dataset.lang ? el.dataset.lang : "it";
let snapOffset = el.dataset.snapOffset ? el.dataset.snapOffset : 0;
//widget-fe.sdch.develondigital.com/
https: ReactDOM.render(<App lang={lang} snapOffset={parseInt(snapOffset)} />, el);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
