import React from "react";
import PropTypes from "prop-types";
import styles from "./InputField.module.sass";
import CustomDataPicker from "../CustomDataPicker/CustomDataPicker";
import InputSelect from "../InputSelect/InputSelect";
import MultiCounter from "../MultiCounter/MultiCounter";
function InputField(props) {
  const { title, type, inputProps, onChange, disabled, lang } = props;

  let inputClassesNames = styles.inputField;
  if (disabled) {
    inputClassesNames += " " + styles.inputFieldDisabled;
  }

  const camelize = (str) => {
    return str
      .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
        return index === 0 ? word.toLowerCase() : word.toUpperCase();
      })
      .replace(/\s+/g, "");
  };

  const renderSelect = (name, inputProps) => {
    return <InputSelect {...inputProps} name={name} onChange={onChange} />;
  };

  const renderDate = (inputProps) => {
    return (
      <CustomDataPicker
        lang={lang}
        {...inputProps}
        closeOnScroll={true}
        locale="it"
        calendarStartDay={1}
        dateFormat={"dd/MM/yyyy"}
        onChange={onChange}
      />
    );
  };

  const renderMultiCounter = (inputProps) => {
    return <MultiCounter {...inputProps} lang={lang} onChange={onChange} />;
  };

  const renderInputType = (type, name, inputProps) => {
    switch (type) {
      case "select":
        return renderSelect(name, inputProps);
      case "date":
        return renderDate(inputProps);
      case "multiCounter":
        return renderMultiCounter(inputProps);
      default:
        return null;
    }
  };

  return (
    <div className={inputClassesNames}>
      <label htmlFor={camelize(title)}>{title}</label>
      <div className={styles.inputFieldContainer}>
        {renderInputType(type, camelize(title), inputProps)}
      </div>
    </div>
  );
}

InputField.propTypes = {
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  inputProps: PropTypes.object,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

InputField.defaultProps = {
  disabled: false,
};

export default InputField;
