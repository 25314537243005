import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import customStyles from "./selectCustomStyle.js";

const InputSelect = (props) => {
  const { options, selected, onChange, name, placeholder } = props;
  const selectRef = useRef(null);

  useEffect(() => {
    if (selected === null || selected === '') {
      selectRef.current.clearValue();
    }
  });

  return (
    <Select
      name={name}
      defaultInputValue={selected}
      styles={customStyles}
      onChange={onChange}
      options={options}
      placeholder={placeholder}
      ref={selectRef}
    />
  );
};

InputSelect.propTypes = {
  options: PropTypes.any.isRequired,
  selected: PropTypes.any,
  onChange: PropTypes.func,
  name: PropTypes.string.isRequired,
};

export default InputSelect;
