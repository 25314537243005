import React, { Fragment, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { getCities, getSearchUrl, getDates } from "../../helpers/apiFunctions";
import styles from "./BookingWidget.module.sass";
import CheckboxInput from "../CheckboxInput/CheckboxInput";
import GoAndReturn from "./GoAndReturnChoice/GoAndReturnChoice";
import InputField from "../InputField/InputField";
import SearchBtn from "./SearchBtn/SearchBtn";
import { OnClickOutside, OnPageScroll } from "../../helpers/customHooks";
import InfoBox from "./InfoBox/InfoBox";
import CloseBtn from "./CloseBtn/CloseBtn";
import { i18n } from "../../data/i18n";

function BookingWidget(props) {
  let offsetY = useRef(0);
  const WIDGET_TOP_HEIGHT = 50;
  const {
    snapOffset,
    fullBlock,
    fullBlockShowExtra,
    message,
    lang,
    openMobileExternal,
  } = props;
  const rootElRef = useRef(null);
  const [cities, setCities] = useState([]);
  const [isSticky, setIsSticky] = useState(false);
  const [goCity, setGoCity] = useState(null);
  const [returnCity, setReturnCity] = useState(null);
  const [flexibleDates, setFlexibleDates] = useState(false);
  const [widgetFocus, setWidgetFocus] = useState(false);
  const [openMobile, setOpenMobile] = useState(false);
  const [submitEnabled, setSubmitEnabled] = useState(false);
  const [goAndReturn, setGoAndReturn] = useState(true);
  const [passengers, setPassengers] = useState([
    {
      label: i18n(lang)["adults"],
      key: "adulto",
      tot: 0,
      subtitle: "",
      limit: { down: 0, up: 4 },
    },
    {
      label: i18n(lang)["children"],
      key: "bambino",
      tot: 0,
      subtitle: i18n(lang)["fromTo"],
      limit: { down: 0, up: 4 },
    },
    {
      label: i18n(lang)["babies"],
      key: "neonato",
      tot: 0,
      subtitle: "",
      limit: { down: 0, up: 4 },
    },
  ]);
  const [starDate, setStartDate] = React.useState({
    date: null,
    includeDates: [],
  });
  const [endDate, setEndDate] = React.useState({
    date: null,
    includeDates: [],
  });

  const onClickMobileOpen = () => {
    setOpenMobile(true);
  };

  const onClickMobileClose = () => {
    setOpenMobile(false);
  };

  const onClickOutideHandler = () => {
    setWidgetFocus(false);
  };

  OnClickOutside(rootElRef, onClickOutideHandler);

  const onPageScrollHandler = (e) => {
    if (rootElRef.current) {
      const rect = rootElRef.current.getBoundingClientRect();
      const offsetForHide = 100;
      const posYOnHide = -rect.height - offsetForHide;
      let direction = offsetY.current > rect.top ? 1 : -1;
      offsetY.current = rect.top;
      let _isSticky = offsetY.current <= -rect.height - offsetForHide;
      setIsSticky(_isSticky);
      if (!isSticky) {
        setWidgetFocus(false);
      }
      if (isSticky) {
        rootElRef.current.querySelector(
          "." + styles.bookingWidgetIn
        ).style.top = snapOffset - WIDGET_TOP_HEIGHT + "px";
        if (direction === -1) {
          rootElRef.current.querySelector(
            "." + styles.bookingWidgetIn
          ).style.top = posYOnHide + "px";
        }
      } else {
        rootElRef.current.querySelector(
          "." + styles.bookingWidgetIn
        ).style.top = posYOnHide + "px";
      }
    }
  };

  if (!fullBlock) {
    OnPageScroll(rootElRef, onPageScrollHandler);
  }

  useEffect(() => {
    // I dati iniziali vengono presi dal dom o tramite chiamata API?
    getCities(onGetCities, null, { "Accept-Language": lang });
  }, []);

  useEffect(() => {
    if (openMobileExternal > 0) setOpenMobile(true);
  }, [openMobileExternal]);

  useEffect(() => {
    let totPassengers =
      passengers[0].tot + passengers[1].tot + passengers[2].tot;
    if (
      starDate.date &&
      endDate.date &&
      goCity?.iata_code &&
      returnCity?.iata_code &&
      totPassengers > 0 &&
      passengers[0].tot > 0
    ) {
      setSubmitEnabled(true);
    } else {
      setSubmitEnabled(false);
    }
  });

  const onGetCities = (data) => {
    setCities(data);
  };

  const dateToString = (date) => {
    var dd = String(date.getDate()).padStart(2, "0");
    var mm = String(date.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = date.getFullYear();
    return dd + "/" + mm + "/" + yyyy;
  };

  const onGetDates = (data) => {
    const allowedDates = data.map((date) => {
      const arrDate = date.split("/");
      const year = parseInt(arrDate[2]);
      const month = parseInt(arrDate[1]) - 1;
      const day = parseInt(arrDate[0]);
      return new Date(year, month, day);
    });
    onChangeStarDate({ includeDates: allowedDates });
    onChangeEndDate({ includeDates: allowedDates });
  };

  const onChangeFlexbileDate = () => {
    setFlexibleDates(!flexibleDates);
  };

  const onChangeGoAndReturn = (newGoAndReturn) => {
    setGoAndReturn(newGoAndReturn === "goAndReturn");
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const objSearch = {
      departure_date: dateToString(new Date(starDate.date)),
      return_date: dateToString(new Date(endDate.date)),
      departure_airport: goCity.iata_code,
      destination_airport: returnCity.iata_code,
      trip_type: goAndReturn ? "R" : "O",
      flexible: flexibleDates,
      adults: passengers[0].tot.toString(),
      children: passengers[1].tot.toString(),
      infants: passengers[2].tot.toString(),
    };
    getSearchUrl(
      (url) => {
        window.location.href = url;
      },
      objSearch,
      null,
      { "Accept-Language": lang }
    );
  };

  const updateDateParams = (oldDate, newDate) => {
    const updatedDate = { ...oldDate };
    for (const [key, value] of Object.entries(newDate)) {
      updatedDate[key] = value;
    }
    return updatedDate;
  };

  const onChangeStarDate = (newStarDate) => {
    const newDate = updateDateParams(starDate, newStarDate);
    setStartDate(newDate);
  };

  const onChangeEndDate = (newEndDate) => {
    const newDate = updateDateParams(starDate, newEndDate);
    setEndDate(newDate);
  };

  const onChangePassengers = (updatedPassengers) => {
    setPassengers(updatedPassengers);
  };

  const onChangeGoCity = (newCity) => {
    // setReturnCity({ value: 0, label: "", isDisabled: false });
    if (newCity) {
      const newGoCity = cities.find((city) => city.id === newCity?.value);
      if (
        !newGoCity?.connections.find(
          (connection) => connection.id === returnCity?.id
        )
      ) {
        setReturnCity(null);
      }
      setGoCity(newGoCity);
    }
  };

  const onChangeReturnCity = (newCity) => {
    if (newCity) {
      setReturnCity(cities.find((city) => city.id === newCity.value));
    }
  };

  useEffect(() => {
    if (goCity && returnCity) {
      getDates(
        onGetDates,
        goCity.iata_code + "-" + returnCity.iata_code,
        null,
        { "Accept-Language": lang }
      );
    }
  }, [goCity, returnCity]);

  let bookingWidgetClassNames = styles.bookingWidget;
  let bookingWidgetContainerClassNames = styles.bookingWidgetContainer;
  if (fullBlock) {
    bookingWidgetClassNames += " " + styles["bookingWidget--full-block"];
    bookingWidgetContainerClassNames +=
      " " + styles["bookingWidgetContainer--full-block"];
  } else {
    if (openMobile) {
      bookingWidgetClassNames += " " + styles["bookingWidget--mobile-open"];
    }
  }
  if (isSticky && !fullBlock) {
    bookingWidgetClassNames += " " + styles.bookingWidgetSticky;
    if (widgetFocus) {
      bookingWidgetClassNames += " " + styles.bookingWidgetStickyFocus;
    }
  }

  let checkEnabledGoCities = false;
  if (returnCity) {
    checkEnabledGoCities = true;
    if (goCity) {
      checkEnabledGoCities = false;
    }
  }

  const goCitiesOptions = cities.map((city) => ({
    value: city.id,
    label: city["name_" + lang],
    isDisabled: checkEnabledGoCities
      ? !returnCity.connections.find((connection) => connection.id === city.id)
      : false,
  }));

  const returnCitiesOptions = cities.map((city) => ({
    value: city.id,
    label: city["name_" + lang],
    isDisabled: goCity
      ? !goCity.connections.find((connection) => connection.id === city.id)
      : false,
  }));

  const startDatePosInArray = endDate.includeDates.findIndex((date) => {
    return new Date(date).toString() === new Date(starDate.date).toString();
  });
  const endDateFiltered =
    startDatePosInArray >= 0
      ? [...endDate.includeDates].splice(startDatePosInArray)
      : endDate;

  return (
    <div className={bookingWidgetContainerClassNames}>
      {message && <InfoBox title={message.title} message={message.message} />}
      <div className={styles.searchMobile}>
        <SearchBtn
          title={i18n(lang)["searchAFlight"]}
          mobile={true}
          onClick={onClickMobileOpen}
          enabled={submitEnabled}
        />
      </div>
      <form
        onSubmit={onSubmit}
        className={bookingWidgetClassNames}
        ref={rootElRef}
        onClick={() => setWidgetFocus(true)}
      >
        <div className={styles.bookingCloseMobileBtn}>
          <CloseBtn onClick={() => onClickMobileClose()} />
        </div>
        <div className={styles.bookingWidgetIn}>
          <div className={styles.bookingWidgetTop}>
            <GoAndReturn
              onChange={onChangeGoAndReturn}
              lang={lang}
              stickyEnabled={isSticky}
            />
          </div>
          <div className={styles.bookingWidgetBottom}>
            <div className={styles.bookingWidgetBottomIn}>
              <div className={styles.bookingWidgetBottomMain}>
                <div className={styles.bookingWidgetInput}>
                  <InputField
                    title={i18n(lang)["departure"]}
                    inputProps={{
                      placeholder: i18n(lang)["departureFrom"],
                      options: goCitiesOptions,
                      selected: goCity ? goCity.id : "",
                    }}
                    onChange={onChangeGoCity}
                    type={"select"}
                  />
                </div>

                <div className={styles.bookingWidgetInput}>
                  <InputField
                    title={i18n(lang)["arrival"]}
                    inputProps={{
                      placeholder: i18n(lang)["arrivalTo"],
                      options: returnCitiesOptions,
                      selected: returnCity ? returnCity.id : "",
                    }}
                    onChange={onChangeReturnCity}
                    type={"select"}
                  />
                </div>

                <div className={styles.bookingWidgetInput}>
                  <InputField
                    title={i18n(lang)["departureDate"]}
                    onChange={(date) => onChangeStarDate({ date })}
                    lang={lang}
                    inputProps={{
                      selected: starDate.date,
                      includeDates: starDate.includeDates,
                    }}
                    type={"date"}
                  />
                </div>
                <div className={styles.bookingWidgetInput}>
                  <InputField
                    title={i18n(lang)["returnDate"]}
                    disabled={!goAndReturn}
                    lang={lang}
                    onChange={(date) => onChangeEndDate({ date })}
                    inputProps={{
                      selected: endDate.date,
                      includeDates: endDateFiltered,
                    }}
                    type={"date"}
                  />
                </div>
                {((fullBlockShowExtra && fullBlock) || !fullBlock) && (
                  <div className={styles.bookingWidgetInput}>
                    <InputField
                      title={i18n(lang)["passengers"]}
                      inputProps={{
                        options: passengers,
                      }}
                      lang={lang}
                      onChange={onChangePassengers}
                      type={"multiCounter"}
                    />
                  </div>
                )}

                {!fullBlock && (
                  <div
                    className={
                      styles.bookingWidgetInput +
                      " " +
                      styles.bookingWidgetInputSearch
                    }
                  >
                    <SearchBtn
                      title={i18n(lang)["search"]}
                      enabled={submitEnabled}
                    />
                  </div>
                )}
              </div>
              {((fullBlockShowExtra && fullBlock) || !fullBlock) && (
                <div className={styles.bookingWidgetBottomFooter}>
                  <CheckboxInput
                    label={i18n(lang)["flexibleDates"]}
                    checked={flexibleDates}
                    onChange={onChangeFlexbileDate}
                  />
                </div>
              )}

              <div
                className={
                  styles.bookingWidgetInput +
                  " " +
                  styles.bookingWidgetInputSearch +
                  " " +
                  styles.bookingWidgetInputSearchEnd
                }
              >
                <SearchBtn
                  title={i18n(lang)["searchFlight"]}
                  enabled={submitEnabled}
                />
              </div>
            </div>
            <div className={styles.arrow} />
          </div>
        </div>
      </form>
    </div>
  );
}

BookingWidget.propTypes = {
  fullBlock: PropTypes.bool,
  fullBlockShowExtra: PropTypes.bool,
  lang: PropTypes.string,
  message: PropTypes.object,
  openMobileExternal: PropTypes.number,
  snapOffset: PropTypes.number,
};

BookingWidget.defaultProps = {
  fullBlock: false,
  fullBlockShowExtra: true,
  lang: "it",
  message: null,
  openMobileExternal: 0,
  snapOffset: 70,
};

export default BookingWidget;
