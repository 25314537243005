const translations = {
  it: {
    goAndReturn: "Andata e ritorno",
    onlyGo: "Solo andata",
    departure: "Partenza",
    arrival: "Arrivo",
    arrivalTo: "Arrivo a",
    departureDate: "Data partenza",
    departureFrom: "Partenza da",
    returnDate: "Data di ritorno",
    passenger: "Passeggero",
    passengers: "Passeggeri",
    flexibleDates: "Date flessibili (3 giorni prima e dopo)",
    search: "Cerca",
    searchFlight: "Cerca volo",
    searchAFlight: "Cerca un volo",
    adults: "Adulti",
    children: "Bambini",
    babies: "Neonati",
    fromTo: "Da 5 a 11 anni"
  },
  en: {
    goAndReturn: "Go and return",
    onlyGo: "Only go",
    departure: "Departure",
    arrival: "Arrival",
    arrivalTo: "Arrival to",
    departureDate: "Departure date",
    departureFrom: "Departure from",
    returnDate: "Return date",
    passenger: "Passenger",
    passengers: "Passengers",
    flexibleDates: "Flexible dates (3 days even and after)",
    search: "Search",
    searchFlight: "Search flight",
    searchAFlight: "Search a flight",
    adults: "Adults",
    children: "Children",
    babies: "Babies",
    fromTo: "From 5 to 11"
  },
  de: {
    goAndReturn: "Andata e ritorno",
    onlyGo: "Solo andata",
    departure: "Partenza",
    arrival: "Arrivo",
    arrivalTo: "Arrivo a",
    departureDate: "Data partenza",
    departureFrom: "Partenza da",
    returnDate: "Data di ritorno",
    passenger: "Passeggero",
    passengers: "Passeggeri",
    flexibleDates: "Date flessibili (3 giorni prima e dopo)",
    search: "Cerca",
    searchFly: "Cerca volo",
    searchAFlight: "Cerca un volo",
    adults: "Adulti",
    children: "Bambini",
    babies: "Neonati",
    fromTo: "Da 5 a 11 anni"
  },
};

const i18n = (lang) => {
  return translations[lang];
};

module.exports.i18n = i18n;
