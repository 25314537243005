import PropTypes from "prop-types";
import styles from "./SearchBtn.module.sass";

const SearchBtn = (props) => {
  const { title, mobile, enabled } = props;

  const renderArrowSvg = () => {
    return (
      <svg
        width="25"
        height="28"
        viewBox="0 0 25 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M24.7334 13.3301L11.5312 13.3301L6.60153e-05 -0.000678317L13.2022 -0.000677163L24.7334 13.3301Z"
          fill={mobile ? '#FA4E37' : 'white'}
        />
        <path
          d="M6.48499e-05 28L13.2022 28L24.7334 14.6692L11.5312 14.6692L6.48499e-05 28Z"
          fill={mobile ? '#FA4E37' : 'white'}
        />
      </svg>
    );
  };

  const onClick = () => {
    if(props.onClick){
      props.onClick();
    }
  }

  let classNames = styles.searchBtn;
  if (mobile) {
    classNames += " " + styles.searchBtnMobile;
  }
  if (!enabled) {
    classNames += " " + styles['searchBtnMobile--disabled'];
  }

  return (
    <button className={classNames} type="submit" onClick={onClick}>
      {title}
      {renderArrowSvg()}
    </button>
  );
};

SearchBtn.propTypes = {
  title: PropTypes.string,
  mobile: PropTypes.bool,
  enabled: PropTypes.bool
};

SearchBtn.defaultProps = {
  title: "",
  mobile: false,
  enabled: false
};

export default SearchBtn;
