// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CloseBtn_closeBtn__JsL9a {\n  background: #FFFFFF;\n  border: 0.5px solid #D2D2D2;\n  box-sizing: border-box;\n  border-radius: 50%;\n  width: 32px;\n  height: 32px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  cursor: pointer; }\n", "",{"version":3,"sources":["webpack://src/components/BookingWidget/CloseBtn/CloseBtn.module.sass"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,2BAA2B;EAC3B,sBAAsB;EACtB,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,eAAe,EAAA","sourcesContent":[".closeBtn {\n  background: #FFFFFF;\n  border: 0.5px solid #D2D2D2;\n  box-sizing: border-box;\n  border-radius: 50%;\n  width: 32px;\n  height: 32px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  cursor: pointer; }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"closeBtn": "CloseBtn_closeBtn__JsL9a"
};
export default ___CSS_LOADER_EXPORT___;
