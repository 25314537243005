import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";
import styles from "./CustomDataPicker.module.sass";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./react-datepicker-custom.css";
import it from "date-fns/locale/it";
import en from "date-fns/locale/en-IN";
import de from "date-fns/locale/de";

const calendarIcon = require("./assets/calendarIcon.svg");

const CustomDataPicker = (props) => {
  const [focus, setFocus] = useState(false);

  const InputPlaceholder = React.forwardRef(({ value, onClick }, ref) => {
    let classes = styles.inputPlaceholder;
    if (focus) classes += " " + styles.inputPlaceholderFocus;
    return (
      <div
        onClick={onClick}
        ref={ref}
        className={classes}
        style={{
          background: "url(" + calendarIcon.default + ") no-repeat 95% center",
        }}
      >
        {value ? value : "--/--/--"}
      </div>
    );
  });

  useEffect(() => {
    switch (props.lang) {
      case "en":
        registerLocale("en", en);
        break;
      case "de":
        registerLocale("de", de);
        break;
      default:
      case "it":
        registerLocale("it", it);
        break;
    }
  }, []);

  return (
    <div
      style={{
        pointerEvents: props.includeDates.length > 0 ? "all" : "none",
      }}
    >
      <DatePicker
        {...props}
        customInput={<InputPlaceholder />}
        onCalendarOpen={() => setFocus(true)}
        onCalendarClose={() => setFocus(false)}
      />
    </div>
  );
};

// All props from DataPicker: https://github.com/Hacker0x01/react-datepicker/blob/master/docs/datepicker.md

CustomDataPicker.propTypes = {};

// export default CustomDataPicker;

export default React.memo(CustomDataPicker, (prevProps, nextProps) => {
  return JSON.stringify(prevProps) === JSON.stringify(nextProps);
});
