// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MultiCounterMenu_multiCounterMenu__kPgGL {\n  position: absolute;\n  top: 60px;\n  left: 0;\n  width: 100%;\n  height: 152px;\n  margin: 5px 0;\n  border-top-left-radius: 0;\n  border-top-right-radius: 0;\n  border-bottom-left-radius: 10px;\n  border-bottom-right-radius: 10px;\n  box-shadow: 0px 12px 25px rgba(0, 0, 0, 0.1);\n  background-color: #fff;\n  padding: 0 10px;\n  z-index: 1; }\n", "",{"version":3,"sources":["webpack://src/components/MultiCounter/MultiCounterMenu/MultiCounterMenu.module.sass"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,SAAS;EACT,OAAO;EACP,WAAW;EACX,aAAa;EACb,aAAa;EACb,yBAAyB;EACzB,0BAA0B;EAC1B,+BAA+B;EAC/B,gCAAgC;EAChC,4CAA4C;EAC5C,sBAAsB;EACtB,eAAe;EACf,UAAU,EAAA","sourcesContent":[".multiCounterMenu {\n  position: absolute;\n  top: 60px;\n  left: 0;\n  width: 100%;\n  height: 152px;\n  margin: 5px 0;\n  border-top-left-radius: 0;\n  border-top-right-radius: 0;\n  border-bottom-left-radius: 10px;\n  border-bottom-right-radius: 10px;\n  box-shadow: 0px 12px 25px rgba(0, 0, 0, 0.1);\n  background-color: #fff;\n  padding: 0 10px;\n  z-index: 1; }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"multiCounterMenu": "MultiCounterMenu_multiCounterMenu__kPgGL"
};
export default ___CSS_LOADER_EXPORT___;
