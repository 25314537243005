import React from "react";
import PropTypes from "prop-types";

const RemoveIcon = (props) => {
  const { enabled } = props;
  const strokeColor = enabled ? "#009EB3" : "#777777";
  
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.7225 18.3327C15.2776 18.3327 18.9703 14.6017 18.9703 9.99935C18.9703 5.39698 15.2776 1.66602 10.7225 1.66602C6.1673 1.66602 2.47461 5.39698 2.47461 9.99935C2.47461 14.6017 6.1673 18.3327 10.7225 18.3327Z"
        stroke={strokeColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.42383 10H14.0221"
        stroke={strokeColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

RemoveIcon.propTypes = {
  enabled: PropTypes.bool,
};

RemoveIcon.defaultProps = {
  enabled: true,
};

export default RemoveIcon;
