const locationIcon = require("./assets/locationIcon.svg");

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    color: state.isFocused ? "#009EB3" : "#1F1F1F",
    backgroundColor: "transparent !important",
    opacity: state.isDisabled ? 0.5 : 1,
    cursor: state.isDisabled ? "default" : "pointer",
  }),
  container: (provided, state) => ({
    ...provided,
    height: "100%",
  }),
  control: (provided, state) => ({
    ...provided,
    height: "100%",
    border: state.menuIsOpen
      ? "1px solid #009EB3 !important"
      : "1px solid #E8E8E8 !important",
    boxShadow: "none",
    borderRadius: "5px",
    background: "url(" + locationIcon.default + ") no-repeat 95% center",
    backgroundColor: state.menuIsOpen ? "#F4F4F4 !important" : "transparent",
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    display: "none",
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    display: "none",
  }),
  menu: (provided, state) => {
    return {
      ...provided,
      borderTopLeftRadius: "0px",
      borderTopRightRadius: "0px",
      borderBottomLeftRadius: "10px",
      borderBottomRightRadius: "10px",
      opacity: state.isDisabled ? 0.5 : 1,
      boxShadow: "0px 12px 25px rgba(0, 0, 0, 0.1);",
    };
  },
};

export default customStyles;
