// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CustomDataPicker_inputPlaceholder__189Tk {\n  width: 100%;\n  height: 100%;\n  border: 1px solid #E8E8E8;\n  border-radius: 5px;\n  padding-left: 10px;\n  line-height: 56px;\n  cursor: default; }\n  .CustomDataPicker_inputPlaceholder__189Tk.CustomDataPicker_inputPlaceholderFocus__r8DLn {\n    background-color: #F4F4F4;\n    border: 1px solid #009EB3; }\n", "",{"version":3,"sources":["webpack://src/components/CustomDataPicker/CustomDataPicker.module.sass"],"names":[],"mappings":"AAEA;EACE,WAAW;EACX,YAAY;EACZ,yBAAyB;EACzB,kBAAkB;EAClB,kBAAkB;EAClB,iBAAiB;EACjB,eAAe,EAAA;EAPjB;IASI,yBAAyB;IACzB,yBAAyB,EAAA","sourcesContent":["\n\n.inputPlaceholder {\n  width: 100%;\n  height: 100%;\n  border: 1px solid #E8E8E8;\n  border-radius: 5px;\n  padding-left: 10px;\n  line-height: 56px;\n  cursor: default;\n  &.inputPlaceholderFocus {\n    background-color: #F4F4F4;\n    border: 1px solid #009EB3; } }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputPlaceholder": "CustomDataPicker_inputPlaceholder__189Tk",
	"inputPlaceholderFocus": "CustomDataPicker_inputPlaceholderFocus__r8DLn"
};
export default ___CSS_LOADER_EXPORT___;
