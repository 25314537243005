import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import styles from "./MultiCounter.module.sass";
import MultiCounterMenu from "./MultiCounterMenu/MultiCounterMenu";
import { OnClickOutside } from "../../helpers/customHooks";
import { i18n } from "../../data/i18n.js";
const userIcon = require("./assets/userIcon.svg");

const MultiCounter = (props) => {
  const { options, onChange, lang } = props;
  const [showMenu, setShowMenu] = useState(false);
  const rootElRef = useRef(null);
  const totPassengers = options.reduce((prev, cur) => {
    return { tot: prev.tot + cur.tot };
  });
  const onClickOutideHandler = () => {
    setShowMenu(false);
  };
  OnClickOutside(rootElRef, onClickOutideHandler);

  let rootStyle = styles.multiCounter;
  if (showMenu) {
    rootStyle += " " + styles.multiCounterActived;
  }

  return (
    <div
      className={rootStyle}
      onClick={() => setShowMenu(true)}
      ref={rootElRef}
    >
      <div
        className={styles.multiCounterInput}
        style={{
          background: "url(" + userIcon.default + ") no-repeat 95% center",
        }}
      >
        {`${totPassengers.tot} ${
          totPassengers.tot === 1
            ? " " + i18n(lang)["passenger"]
            : " " + i18n(lang)["passengers"]
        }`}
      </div>
      <MultiCounterMenu
        choices={options}
        onChange={onChange}
        visible={showMenu}
      />
    </div>
  );
};

MultiCounter.propTypes = {
  options: PropTypes.array,
  onChange: PropTypes.func,
};

export default MultiCounter;
