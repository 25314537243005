// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MultiCounter_multiCounter__3FRfT {\n  position: relative;\n  width: 100%;\n  height: 100%; }\n  .MultiCounter_multiCounter__3FRfT .MultiCounter_multiCounterInput__3NRcg {\n    width: 100%;\n    height: 100%;\n    border: 1px solid #E8E8E8;\n    border-radius: 5px;\n    padding-left: 10px;\n    line-height: 56px;\n    cursor: default; }\n  .MultiCounter_multiCounter__3FRfT.MultiCounter_multiCounterActived__6P8l5 {\n    background-color: #F4F4F4; }\n    .MultiCounter_multiCounter__3FRfT.MultiCounter_multiCounterActived__6P8l5 .MultiCounter_multiCounterInput__3NRcg {\n      border: 1px solid #009EB3; }\n", "",{"version":3,"sources":["webpack://src/components/MultiCounter/MultiCounter.module.sass"],"names":[],"mappings":"AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,YAAY,EAAA;EAHd;IAKI,WAAW;IACX,YAAY;IACZ,yBAAyB;IACzB,kBAAkB;IAClB,kBAAkB;IAClB,iBAAiB;IACjB,eAAe,EAAA;EAXnB;IAaI,yBAAyB,EAAA;IAb7B;MAeM,yBAAyB,EAAA","sourcesContent":["@import '../../sass/global';\n\n.multiCounter {\n  position: relative;\n  width: 100%;\n  height: 100%;\n  .multiCounterInput {\n    width: 100%;\n    height: 100%;\n    border: 1px solid #E8E8E8;\n    border-radius: 5px;\n    padding-left: 10px;\n    line-height: 56px;\n    cursor: default; }\n  &.multiCounterActived {\n    background-color: #F4F4F4;\n    .multiCounterInput {\n      border: 1px solid #009EB3; } } }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"multiCounter": "MultiCounter_multiCounter__3FRfT",
	"multiCounterInput": "MultiCounter_multiCounterInput__3NRcg",
	"multiCounterActived": "MultiCounter_multiCounterActived__6P8l5"
};
export default ___CSS_LOADER_EXPORT___;
