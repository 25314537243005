import { useState } from "react";
import PropTypes from "prop-types";
import RadioInput from "../../RadioInput/RadioInput";
import styles from "./GoAndReturn.module.sass";
import { i18n } from "../../../data/i18n";

const GoAndReturn = (props) => {
  const [selection, setSelection] = useState("goAndReturn");
  const { onChange, lang, stickyEnabled } = props;

  const onChangeSelection = (newSelection) => {
    setSelection(newSelection);
    onChange(newSelection);
  };

  return (
    <div
      className={`${styles.goAndReturn} ${
        stickyEnabled ? styles.goAndReturnSticky : ""
      }`}
    >
      <div
        className={`${selection === "goAndReturn" ? styles.selectedTab : ""}`}
      >
        <RadioInput
          label={i18n(lang)["goAndReturn"]}
          checked={selection === "goAndReturn"}
          name={"goAndReturn"}
          onChange={() => onChangeSelection("goAndReturn")}
        />
      </div>
      <div
        className={`${selection !== "goAndReturn" ? styles.selectedTab : ""}`}
      >
        <RadioInput
          label={i18n(lang)["onlyGo"]}
          checked={selection === "onlyGo"}
          name={"goAndReturn"}
          onChange={() => onChangeSelection("onlyGo")}
        />
      </div>
    </div>
  );
};

GoAndReturn.propTypes = {
  onChange: PropTypes.func,
  stickyEnabled: PropTypes.bool,
};

GoAndReturn.defaultProps = {
  onChange: null,
  stickyEnabled: false,
};

export default GoAndReturn;
