// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".InputField_inputField__2fQKJ.InputField_inputFieldDisabled__dSFKH {\n  pointer-events: none;\n  opacity: 0.6; }\n\n.InputField_inputField__2fQKJ .InputField_inputFieldContainer__dkWDd {\n  width: 100%;\n  height: 56px;\n  margin-top: 5px; }\n", "",{"version":3,"sources":["webpack://src/components/InputField/InputField.module.sass"],"names":[],"mappings":"AAAA;EAEI,oBAAoB;EACpB,YAAY,EAAA;;AAHhB;EAKI,WAAW;EACX,YAAY;EACZ,eAAe,EAAA","sourcesContent":[".inputField {\n  &.inputFieldDisabled {\n    pointer-events: none;\n    opacity: 0.6; }\n  .inputFieldContainer {\n    width: 100%;\n    height: 56px;\n    margin-top: 5px; } }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputField": "InputField_inputField__2fQKJ",
	"inputFieldDisabled": "InputField_inputFieldDisabled__dSFKH",
	"inputFieldContainer": "InputField_inputFieldContainer__dkWDd"
};
export default ___CSS_LOADER_EXPORT___;
