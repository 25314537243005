import React from "react";
import PropTypes from "prop-types";

const AddIcon = (props) => {
  const {enabled} = props;
  const strokeColor = enabled ? '#009EB3' : '#777777'

  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.4149 18.3327C14.97 18.3327 18.6627 14.6017 18.6627 9.99935C18.6627 5.39698 14.97 1.66602 10.4149 1.66602C5.85969 1.66602 2.16699 5.39698 2.16699 9.99935C2.16699 14.6017 5.85969 18.3327 10.4149 18.3327Z"
        stroke={strokeColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.415 6.66602V13.3327"
        stroke={strokeColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.11621 10H13.7145"
        stroke={strokeColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

AddIcon.propTypes = {
  enabled: PropTypes.bool,
};

AddIcon.defaultProps = {
  enabled: true
};

export default AddIcon;
