const { useEffect } = require("react");

/**
 * Hook that alerts clicks outside of the passed ref from https://codesandbox.io/s/outside-alerter-hooks-lmr2y?module=/src/OutsideAlerter.js&file=/src/OutsideAlerter.js:86-680
 */
 const useOutside = (ref, fcn) => {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        if(fcn)
          fcn();
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, fcn]);
}

const OnClickOutside = (ref, fcn) => {
  useOutside(ref, fcn);
}
const useOnPageScroll = (ref, fcn) => {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        if(fcn)
          fcn();
      }
    }
    // Bind the event listener
    document.addEventListener("scroll", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("scroll", handleClickOutside);
    };
  }, [ref, fcn]);
}

const OnPageScroll = (ref, fcn) => {
  useOnPageScroll(ref, fcn);
}


module.exports.useOutside = useOutside;
module.exports.OnClickOutside = OnClickOutside;

module.exports.useOnPageScroll = useOnPageScroll;
module.exports.OnPageScroll = OnPageScroll;