import PropTypes from "prop-types";
import styles from "./RadioInput.module.sass";

const RadioInput = (props) => {
  const { label, checked, name, onChange } = props;
  let rootClassName = styles.radioContainer;
  if (checked) {
    rootClassName += " " + styles["radioContainer--checked"];
  }
  return (
    <div className={rootClassName} onClick={onChange}>
      <label>{label}</label>
      <input type="radio" checked={checked} name={name} onChange={onChange} />
      <span className={styles.checkmark} />
    </div>
  );
};

RadioInput.propTypes = {
  label: PropTypes.string,
  checked: PropTypes.bool,
  name: PropTypes.string,
};

export default RadioInput;
