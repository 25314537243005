import PropTypes from "prop-types";
import style from "./CloseBtn.module.sass";

function CloseBtn(props) {
  const renderCloseSvg = () => {
    return (
      <svg
        width="10"
        height="10"
        viewBox="0 0 10 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9 1L1 9"
          stroke="#05164D"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1 1L9 9"
          stroke="#05164D"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  };

  return (
    <div
      className={`${style.closeBtn}`}
      onClick={() => (props.onClick ? props.onClick() : null)}
    >
      {renderCloseSvg()}
    </div>
  );
}

CloseBtn.propTypes = {};

CloseBtn.defaultProps = {};

export default CloseBtn;
