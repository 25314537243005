import React, { useState } from "react";
import PropTypes from "prop-types";
import styles from "./InfoBox.module.sass";

const InfoBox = (props) => {
  const { title, message } = props;

  return (
    <div className={styles.infoBox}>
      <h2 className={styles.infoBoxTitle}>{title}</h2>
      <div
        className={styles.infoBoxMessage}
        dangerouslySetInnerHTML={{ __html: message }}
      />
    </div>
  );
};

InfoBox.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
};

export default InfoBox;
