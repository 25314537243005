import PropTypes from "prop-types";
import styles from "./CheckboxInput.module.sass";

const CheckboxInput = (props) => {
  const { label, checked, onChange } = props;
  return (
    <label className={styles.checkboxInput}>
      {label}
      <input type="checkbox" checked={checked} onChange={onChange} />
      <span className={styles.checkmark}>
        <svg
          width="14"
          height="11"
          viewBox="0 0 14 11"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.5554 1.83203L4.91656 9.47092L1.44434 5.9987"
            stroke="#F4F4F4"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </span>
    </label>
  );
};

CheckboxInput.propTypes = {
  label: PropTypes.string,
  checked: PropTypes.bool,
};

export default CheckboxInput;
