const CITIES_URL = "https://widget-api.sdch.develondigital.com/v1/airports";
const SEARCH_URL = "https://widget-api.sdch.develondigital.com/v1/bookings";
const DATES_URL =
  "https://widget-api.sdch.develondigital.com/v1/flights/dates/";

// const exampleGET = async function (url, onCompleteFcn, onErrorFcn = null) {
//   callApi(url, "GET", null, onCompleteFcn, onErrorFcn);
// };

// const examplePost = async function (
//   url,
//   body = {},
//   onCompleteFcn,
//   onErrorFcn = null
// ) {
//   callApi(url, "POST", body, onCompleteFcn, onErrorFcn);
// };

const getCities = async function (
  onCompleteFcn,
  onErrorFcn = null,
  extraHeaders = {}
) {
  callApi(CITIES_URL, "GET", null, onCompleteFcn, onErrorFcn, extraHeaders);
};

const getDates = async function (
  onCompleteFcn,
  routeString,
  onErrorFcn = null,
  extraHeaders = {}
) {
  callApi(
    DATES_URL + routeString,
    "GET",
    null,
    onCompleteFcn,
    onErrorFcn,
    extraHeaders
  );
};

const getSearchUrl = async function (
  onCompleteFcn,
  postData,
  onErrorFcn = null,
  extraHeaders = {}
) {
  callApi(
    SEARCH_URL,
    "POST",
    postData,
    onCompleteFcn,
    onErrorFcn,
    extraHeaders
  );
};

const callApi = async function (
  url,
  method,
  body = null,
  onCompleteFcn = null,
  onErrorFcn = null,
  extraHeaders = {}
) {
  await fetch(url, {
    method,
    headers: {
      "Content-Type": "application/json",
      ...extraHeaders,
    },
    body: body ? JSON.stringify(body) : null,
  })
    .then((res) => {
      return res
        .clone()
        .json()
        .catch(() => res.text());
    })
    .then((data) => {
      if (data.statusCode === 500) {
        return null;
      }
      if (onCompleteFcn) {
        onCompleteFcn(data);
      }
    })
    .catch((error) => {
      if (onErrorFcn) {
        onErrorFcn(error);
      }
    });
};

const _getCities = getCities;
export { _getCities as getCities };
const _getSearchUrl = getSearchUrl;
export { _getSearchUrl as getSearchUrl };
const _getDates = getDates;
export { _getDates as getDates };
