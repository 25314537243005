import { useState } from "react";
import "./App.css";
import BookingWidget from "./components/BookingWidget/BookingWidget";

function TestBtnHeader(props) {
  return <div onClick={props.onClick}>Open mobile</div>;
}

function App(props) {
  const { lang, snapOffset } = props;
  const [triggerOpenMobile, setTriggerOpenMobile] = useState(0);

  const onClickBtnHeader = () => {
    const newValue = triggerOpenMobile + 1;
    setTriggerOpenMobile(newValue);
  };

  return (
    <div className="App">
      <div className="fakeHeader">
        <TestBtnHeader onClick={onClickBtnHeader} />
      </div>
      <div
        className="hero"
        style={{ backgroundImage: 'url("./Illustrazione.png")' }}
      >
        <div className="container">
          <BookingWidget
            lang={lang}
            snapOffset={snapOffset}
            openMobileExternal={triggerOpenMobile}
          />
        </div>
      </div>
    </div>
  );
}

export default App;
