import React from "react";
import PropTypes from "prop-types";
import styles from "./MultiCounterMenu.module.sass";
import SingleCounter from "./SingleCounter/SingleCounter";

const MultiCounterMenu = (props) => {
  const { choices, onChange, visible } = props;

  const onClickAdd = (choice) => {
    updateChoices(choice, 1);
  };

  const onClickRemove = (choice) => {
    updateChoices(choice, -1);
  };

  const updateChoices = (choiceKey, changeValue) => {
    const updatedChoices = JSON.parse(JSON.stringify(choices));
    const choiceToUpdate = updatedChoices.find((el) => el.key === choiceKey);
    choiceToUpdate.tot += changeValue;
    if (choiceToUpdate.tot > choiceToUpdate.limit.up)
      choiceToUpdate.tot = choiceToUpdate.limit.up;
    if (choiceToUpdate.tot < choiceToUpdate.limit.down)
      choiceToUpdate.tot = choiceToUpdate.limit.down;
    onChange(updatedChoices);
  };

  return (
    <div
      className={styles.multiCounterMenu}
      style={{ display: visible ? "block" : "none" }}
    >
      {choices.map((choice, index) => {
        return (
          <SingleCounter
            choice={choice}
            key={"choice_" + index}
            onClickRemove={onClickRemove}
            onClickAdd={onClickAdd}
          />
        );
      })}
    </div>
  );
};

MultiCounterMenu.propTypes = {
  choices: PropTypes.array,
  onChange: PropTypes.func,
  visible: PropTypes.bool,
};

MultiCounterMenu.defaultProps = {
  visible: false,
};

export default MultiCounterMenu;
