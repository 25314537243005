import React from "react";
import PropTypes from "prop-types";
import styles from "./SingleCounter.module.sass";
import AddIcon from "./AddIcon";
import RemoveIcon from "./RemoveIcon";

const SingleCounter = (props) => {
  const { choice, onClickAdd, onClickRemove } = props;
  const minusEnabled = choice.tot > choice.limit.down;
  const plusEnabld = choice.tot < choice.limit.up;
  return (
    <div className={styles.singleCounter}>
      <div className={styles.singleCounterTop}>
        <div className={styles.label}>
          {choice.label}
          <div className={styles.subtitle}>{choice.subtitle}</div>
        </div>
        <div className={styles.counter}>
          <div
            className={styles.remove}
            style={{ cursor: minusEnabled ? "pointer" : "default" }}
            onClick={minusEnabled ? () => onClickRemove(choice.key) : null}
          >
            <RemoveIcon enabled={minusEnabled} />
          </div>
          <div className={styles.tot}>{choice.tot}</div>
          <div
            className={styles.add}
            style={{ cursor: plusEnabld ? "pointer" : "default" }}
            onClick={plusEnabld ? () => onClickAdd(choice.key) : null}
          >
            <AddIcon enabled={plusEnabld} />
          </div>
        </div>
      </div>
      <div className={styles.singleCounterBottom}></div>
    </div>
  );
};

SingleCounter.propTypes = {
  choice: PropTypes.object,
};

export default SingleCounter;
